<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group
            label="Período"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                v-model="filtros.ano"
                size="sm"
                class="w-25"
                :options="anos"
                text-field="nome"
                value-field="value"
                @change="buscaDados"
              >
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- TABELA -->
        <b-col cols="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="default">
              <b-tr>
                <b-th></b-th>
                <b-th
                  v-for="(mes, index) in meses"
                  :key="index"
                  class="text-center"
                >
                  <a @click="rota(mes)">{{ mes.nome }}</a>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th sticky-column variant="success"
                  >Receita Operacional Bruta</b-th
                >
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="receitaOperacionalBruta[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ receitaOperacionalBruta[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ receitaOperacionalBruta[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger">(-) Impostos</b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="pagImpostos[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ pagImpostos[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ pagImpostos[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th sticky-column>Receita Operacional Líquida</b-th>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="receitaOperacionalLiquida[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{
                      receitaOperacionalLiquida[mes.value - 1] | formataMoeda
                    }}
                  </span>
                  <span v-else class="text-danger">
                    {{
                      receitaOperacionalLiquida[mes.value - 1] | formataMoeda
                    }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger">(-) Custos Fíxos</b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="custosFixos[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ custosFixos[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ custosFixos[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger">(-) Custos Variáveis</b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="custosVariaveis[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ custosVariaveis[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ custosVariaveis[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger">(-) Despesas</b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="despesas[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ despesas[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ despesas[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th sticky-column>Resultado Operacional</b-th>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="resultadoOperacional[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ resultadoOperacional[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ resultadoOperacional[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger">
                  (-) Despesas Não Operacionais / Investimentos
                </b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="
                      despesasNaoOperacionaisInvestimentos[mes.value - 1] >= 0
                    "
                    class="text-primary"
                  >
                    {{
                      despesasNaoOperacionaisInvestimentos[mes.value - 1]
                        | formataMoeda
                    }}
                  </span>
                  <span v-else class="text-danger">
                    {{
                      despesasNaoOperacionaisInvestimentos[mes.value - 1]
                        | formataMoeda
                    }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th sticky-column> L.A.I.R </b-th>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span v-if="lair[mes.value - 1] >= 0" class="text-primary">
                    {{ lair[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ lair[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column variant="danger"> (-) IRPJ / CSLL </b-td>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="irpjCsll[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ irpjCsll[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ irpjCsll[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th sticky-column variant="dark"> Lucro Líquido </b-th>
                <b-td
                  class="text-right"
                  v-for="(mes, index) in meses"
                  :key="index"
                >
                  <span
                    v-if="lucroLiquido[mes.value - 1] >= 0"
                    class="text-primary"
                  >
                    {{ lucroLiquido[mes.value - 1] | formataMoeda }}
                  </span>
                  <span v-else class="text-danger">
                    {{ lucroLiquido[mes.value - 1] | formataMoeda }}
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
    <!--
    {{ receitaOperacionalBruta }}
    {{ pagImpostos }}
    {{ receitaOperacionalLiquida }}
    {{ custosFixos }}
    {{ custosVariaveis }}
    {{ despesas }}
    {{ resultadoOperacional }}
    {{ despesasNaoOperacionaisInvestimentos }}
    {{ lair }}
    {{ irpjCsll }}
    {{ lucroLiquido }}-->
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCard,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BRow,
  BTab,
  BTabs,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BCard,
    BDropdown,
    BDropdownForm,
    BDropdownDivider,
    BTab,
    BTabs,
    BButtonGroup,
    BButtonToolbar,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      filtros: {
        ano: this.$getDate.anoAtual(),
      },
      meses: [
        { value: "1", nome: "janeiro" },
        { value: "2", nome: "fevereiro" },
        { value: "3", nome: "março" },
        { value: "4", nome: "abril" },
        { value: "5", nome: "maio" },
        { value: "6", nome: "junho" },
        { value: "7", nome: "julho" },
        { value: "8", nome: "agosto" },
        { value: "9", nome: "setembro" },
        { value: "10", nome: "outubro" },
        { value: "11", nome: "novembro" },
        { value: "12", nome: "dezembro" },
      ],
      sortBy: "",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      receitaOperacionalBruta: "relatorios/receitaOperacionalBruta",
      pagImpostos: "relatorios/pagImpostos",
      receitaOperacionalLiquida: "relatorios/receitaOperacionalLiquida",
      custosFixos: "relatorios/custosFixos",
      custosVariaveis: "relatorios/custosVariaveis",
      despesas: "relatorios/despesas",
      resultadoOperacional: "relatorios/resultadoOperacional",
      despesasNaoOperacionaisInvestimentos:
        "relatorios/despesasNaoOperacionaisInvestimentos",
      lair: "relatorios/lair",
      irpjCsll: "relatorios/irpjCsll",
      lucroLiquido: "relatorios/lucroLiquido",
    }),

    // ...mapState({
    //  dre: (state) => state.relatorios.dre,
    // }),

    anos() {
      return this.$getDate.getAnoFiltros();
    },
  },

  mounted() {
    this.buscaDados();
  },

  methods: {
    buscaDados() {
      this.$store.dispatch("relatorios/buscaDre", this.filtros);
    },
    rota(mes) {
      
      let url = `${this.$http.defaults.baseURL}/adm/relatorios/dre/detalhado/${mes.value}/${this.filtros.ano}`
      window.open(url, '_blank')
    }
  },

  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      return Number(valor).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY H:mm");
    },

    converteDataSetHora(data, hora) {
      if (!data) return;

      if (!hora) {
        hora = "";
      }

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]} ${hora}`;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
